import { Col, Container, Link, PageBanner, Row, SocialButton, Text, Title } from '@zigurous/react-components'; // prettier-ignore
import React from 'react';
import { Page } from '../components';
import { trackEvent } from '../firebase/analytics';

const metadata = {
  url: 'https://zigurous.com/community',
  title: 'Zigurous • Community',
  description:
    'Zigurous hosts a community Discord server for game developers to collaborate and learn from one another.',
};

const channels = [
  {
    name: 'Welcome',
    items: [
      '📜 Read the #rules of the server',
      '📢 Important server and community #announcements',
      '🗳️ Provide #suggestions for new tutorials and assets',
    ],
    links: [
      { text: '#rules', url: 'https://discord.gg/DdYyWVb' },
      { text: '#announcements', url: 'https://discord.gg/cTTd4XQJhD' },
      { text: '#suggestions', url: 'https://discord.gg/Aq2PRb79Fy' },
    ],
  },
  {
    name: 'Development + Help',
    items: [
      "💬 Share what's on your mind in #general",
      '🧰 Learn about developing with #unity',
      '⌨️ Discuss #programming problems and techniques',
      '🖼️ Share your #artwork and ask for feedback',
      '🧑‍🤝‍🧑 Ask others to #collaborate on projects with you',
    ],
    links: [
      { text: '#general', url: 'https://discord.gg/PhSHtmCtA4' },
      { text: '#unity', url: 'https://discord.gg/V2k23XjA85' },
      { text: '#programming', url: 'https://discord.gg/Kzr37DHXNy' },
      { text: '#artwork', url: 'https://discord.gg/aS3PxVTbsJ' },
      { text: '#collaborate', url: 'https://discord.gg/YxjvYMNSDH' },
    ],
  },
  {
    name: 'Showcase + Sharing',
    items: [
      '⭐ #showcase what you are working on',
      '💎 Post #tutorials you have found to be helpful',
      '🧱 Recommend the best #assets for the community',
    ],
    links: [
      { text: '#showcase', url: 'https://discord.gg/H8ByJDTXcT' },
      { text: '#tutorials', url: 'https://discord.gg/gZXNK3kAHq' },
      { text: '#assets', url: 'https://discord.gg/ucX9afkxpg' },
    ],
  },
];

interface CommunityProps {
  location: Location;
}

export default function Community({ location }: CommunityProps) {
  return (
    <Page className="community" location={location} metadata={metadata}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row>
            <Col className="col col-xl-10">
              <Title subtitle size={5}>
                Discord Server
              </Title>
              <Title as="h1" nowrap>
                Community
              </Title>
              <Text size="lg">
                Our Discord server is a dedicated place to collaborate with
                other game developers, learn about making games, receive help
                and feedback, and much more. If you are looking for a community
                to grow alongside with, then this is a great place for you.
              </Text>
              <SocialButton
                className="margin-top-md margin-bottom-md"
                link="discord"
                onClick={() => {
                  trackEvent('social_button', {
                    content_type: 'button',
                    event_category: 'engagement',
                    event_label: 'Join the Community',
                  });
                }}
                shape="rounded"
                size="md"
                style="outline"
              >
                Join the Community
              </SocialButton>
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="margin-top-xxxl" fluid>
        <Row className="margin-top-xl">
          <Col>
            <Title as="h2" size={3}>
              Channels
            </Title>
          </Col>
        </Row>
        {channels.map(channel => (
          <Row className="margin-top-xl" key={channel.name}>
            <Col>
              <Title as="h3" size={5}>
                {channel.name}
              </Title>
              <ul>
                {channel.items.map((item, index) => {
                  const link = channel.links[index];
                  const words = item.split(' ');
                  return (
                    <li key={item}>
                      {words.map((word, wordIndex) => {
                        if (wordIndex === 0) {
                          return (
                            <span aria-hidden key={wordIndex} role="img">
                              {word}{' '}
                            </span>
                          );
                        } else if (word === link.text) {
                          return (
                            <Link
                              aria-label={link.text.replace('#', '')}
                              external
                              key={wordIndex}
                              to={link.url}
                            >
                              {link.text}{' '}
                            </Link>
                          );
                        } else {
                          return (
                            <React.Fragment key={wordIndex}>
                              {word}{' '}
                            </React.Fragment>
                          );
                        }
                      })}
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        ))}
      </Container>
    </Page>
  );
}
